import * as React from 'react'
import loadable from '@loadable/component'

import { css, styled } from '@thg-commerce/gravity-elements/theme'
import { mq, Text } from '@thg-commerce/gravity-theme'

const Minus = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Minus'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Plus = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Plus'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

export const QuantitySelectorContainer = styled.div<{
  quantityAsLabel?: boolean
  alternateWidth?: boolean
}>`
  ${(props) => props.alternateWidth && 'max-width: 100px;'}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    ${(props) => props.alternateWidth && 'max-width: 160px;'}
  }

  width: ${(props) =>
    props.alternateWidth ? 'auto' : props.quantityAsLabel ? '160px' : '144px'};
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SvgStyles = css<{ isDisabled?: boolean }>`
  width: 24px;
  height: 24px;
  transition: 0.3s ease;
  margin: auto;
  fill: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.colors.palette.greys.light
      : theme.colors.palette.greys.darker};
`

export const StyledMinus = styled(Minus)`
  ${SvgStyles}
`
export const StyledPlus = styled(Plus)`
  ${SvgStyles}
`
export const StyledSvgIcon = styled(SvgIcon)`
  ${SvgStyles}
`

const buttonStyling = css`
  height: 48px;
  width: 48px;
  transition: background 0.3s ease;
  display: flex;
  border-radius: ${(props) =>
    props.theme.elements.quantitySelector.border.borderRadius}px;
  border: ${(props) =>
    `${props.theme.elements.quantitySelector.border.borderWidth}px solid ${props.theme.colors.palette.greys.grey}`};

  &:hover {
    background-color: ${(props) => props.theme.colors.palette.brand.lightest};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.palette.brand.lighter};
  }

  &:focus-visible {
    background-color: ${(props) => props.theme.colors.palette.brand.lightest};
    border: ${(props) =>
        props.theme.elements.quantitySelector.border.borderWidth + 1}px
      solid ${(props) => props.theme.colors.palette.brand.base};
  }

  &:disabled {
    cursor: not-allowed;
    background: none;
    ${StyledMinus} {
      fill: ${(props) => props.theme.colors.palette.greys.light};
      stroke: ${(props) => props.theme.colors.palette.greys.light};
    }
    ${StyledPlus} {
      fill: ${(props) => props.theme.colors.palette.greys.light};
      stroke: ${(props) => props.theme.colors.palette.greys.light};
    }
  }

  outline: none;
`

export const StyledButton = styled.button`
  ${buttonStyling}
`

export const RightButton = styled.button`
  ${buttonStyling}
  border-left: ${(props) =>
    props.theme.elements.quantitySelector.border
      .borderWidth}px solid transparent;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`

export const LeftButton = styled.button`
  ${buttonStyling}
  border-right: ${(props) =>
    props.theme.elements.quantitySelector.border
      .borderWidth}px solid transparent;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
`

export const QuantityLabel = styled.span`
  ${Text('bodyText', 'default')}
`

export const QuantityInputLabel = styled.label<{ alternateWidth?: boolean }>`
  width: ${(props) => (props.alternateWidth ? 'fit-content' : '64px')};
  height: 100%;
`

export const QuantityInput = styled.input`
  height: 100%;
  width: 100%;
  border-top: ${(props) =>
      props.theme.elements.quantitySelector.border.borderWidth}px
    solid ${(props) => props.theme.colors.palette.greys.grey};
  border-bottom: ${(props) =>
      props.theme.elements.quantitySelector.border.borderWidth}px
    solid ${(props) => props.theme.colors.palette.greys.grey};
  border-left: none;
  border-right: none;
  border-radius: 0;

  ${Text('bodyText', 'default')}
  text-align: center;

  &:focus-visible {
    border: ${(props) =>
        props.theme.elements.quantitySelector.border.borderWidth + 1}px
      solid ${(props) => props.theme.colors.palette.brand.base};
    border-radius: 0;
  }

  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }
`
